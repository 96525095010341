<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
            <v-layout row wrap>
                <v-flex lg4 md12 sm12 pr-6 pl-lg-6>
                    <v-layout row wrap justify-end>
                        <v-menu
                            v-model="menuStart"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="computedStartDateFormatted"
                                label="Từ ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange.startDate" no-title @input="menuStart = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg4 md12 sm12 pr-6>
                    <v-layout row wrap justify-end>
                        <v-menu
                            v-model="menuEnd"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="computedEndDateFormatted"
                                label="Đến ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                prepend-icon="fa-calendar-alt"
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dateRange.endDate" no-title @input="menuEnd = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg4 md12 sm12 pr-6>
                    <v-btn @click="filter_data" color="#3480c1" outlined>Xem</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex lg12 v-if="summary_staff">
            <v-data-table :headers="summary_headers" :items="summary_staff" :options.sync="pagination" :server-items-length="summary_staff ? summary_staff.length : 0" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.StaffName }}</td>
                        <td class="text-end">{{ item.RevenueCommissionVNDValue | currency}}</td>
                        <td class="text-end">{{ item.OrderCommissionValue | currency}}</td>
                        <td class="text-end">{{ item.OrderFeeCommissionValue | currency}}</td>
                        <td class="text-end">{{ item.TotalOrderValue | currency}}</td>
                        <td class="text-end">{{ item.DepositFeeCommissionValue | currency}}</td>
                        <td class="text-end">{{ item.TotalValue | currency}}</td>
                    </tr>
                </template>
                </v-data-table>
        </v-flex>
        <v-flex lg12>
            <hpo-filter fixed-header height="750px" :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
            <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.StaffName }}</td>
                        <td>{{ item.CustomerName }}</td>
                        <td>{{ item.OrderID }}</td>
                        <td>{{ item.OrderType | display_value(order_type_list) }}</td>
                        <td class="text-end">{{ item.RevenueCommission | currency('', 1)}}</td>
                        <td class="text-end">{{ item.RevenueCommissionVND | currency}}</td>
                        <td class="text-end">{{ item.RevenueCommissionVNDValue | currency}}</td>
                        <td class="text-end">{{ item.OrderCommission | currency}}</td>
                        <td class="text-end">{{ item.OrderCommissionValue | currency}}</td>
                        <td class="text-end">{{ item.OrderFeeCommission | currency}}</td>
                        <td class="text-end">{{ item.OrderFeeCommissionValue | currency}}</td>
                        <td class="text-end">{{ item.DepositFeeCommission | currency}}</td>
                        <td class="text-end">{{ item.DepositFeeCommissionValue | currency}}</td>
                    </tr>
                </template>
                <template v-slot:body.append v-if="summary">
                    <tr>
                        <td class="font-weight-bold" colspan="4">TỔNG</td>
                        <td class="text-end font-weight-bold">{{summary.RevenueCommission | currency('', 1)}}</td>
                        <td class="text-end font-weight-bold">{{ summary.RevenueCommissionVND | currency}}</td>
                        <td class="text-end font-weight-bold">{{ summary.RevenueCommissionVNDValue | currency}}</td>
                        <td class="text-end font-weight-bold">{{ summary.OrderCommission | currency}}</td>
                        <td class="text-end font-weight-bold">{{ summary.OrderCommissionValue | currency}}</td>
                        <td class="text-end font-weight-bold">{{ summary.OrderFeeCommission | currency}}</td>
                        <td class="text-end font-weight-bold">{{ summary.OrderFeeCommissionValue | currency}}</td>
                        <td class="text-end font-weight-bold">{{ summary.DepositFeeCommission | currency}}</td>
                        <td class="text-end font-weight-bold">{{ summary.DepositFeeCommissionValue | currency}}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";
import moment from "moment";
export default {
  components: {
    "hpo-filter": Filtering
  },
  data() {
    return {
      summary_headers: [
        {
          text: "Nhân viên",
          value: "StaffName",
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Chênh lệch doanh thu",
          value: "RevenueCommissionVNDValue",
          align: "end",
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Doanh thu Order",
          value: "OrderCommissionValue",
          align: "end",
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Cước VCQT đơn Order",
          value: "OrderFeeCommissionValue",
          align: "end",
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Tổng đơn Order",
          value: "TotalOrderValue",
          align: "end",
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Cước VCQT đơn Ký gửi",
          value: "DepositFeeCommissionValue",
          align: "end",
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Tổng",
          value: "TotalValue",
          align: "end",
          dataType: configs.DATA_TYPE["Number"]
        }
      ],
      headers: [
        {
          text: "Nhân viên",
          value: "StaffName",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Khách hàng",
          value: "CustomerName",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["String"]
        },
        {
          text: "Mã đơn hàng",
          value: "OrderID",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Loại đơn",
          value: "OrderType",
          align: "end",
          filterable: true,
          values: configs.ORDER_TYPE_LIST,
          dataType: configs.DATA_TYPE["Enum"]
        },
        {
          text: "Chênh lệch doanh thu (¥)",
          value: "RevenueCommission",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Chênh lệch doanh thu",
          value: "RevenueCommissionVND",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Hoa hồng chênh lệch doanh thu",
          value: "RevenueCommissionVNDValue",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Doanh thu Order",
          value: "OrderCommission",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Hoa hồng doanh thu Order",
          value: "OrderCommissionValue",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Cước VCQT đơn Order",
          value: "OrderFeeCommission",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Hoa hồng cước VCQT đơn Order",
          value: "OrderFeeCommissionValue",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Cước VCQT đơn Ký gửi",
          value: "DepositFeeCommission",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: "Hoa hồng cước VCQT đơn Ký gửi",
          value: "DepositFeeCommissionValue",
          align: "end",
          filterable: true,
          dataType: configs.DATA_TYPE["Number"]
        }
      ],
      pagination: _.cloneDeep(configs.PAGINATION),
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      filterConditions: [],
      quickFilterConditions: [],
      viewName: "thong-ke-hoa-hong-nhan-vien",
      dateRange: {
        startDate: moment
          .utc(Date.now())
          .startOf("month")
          .toISOString()
          .substr(0, 10),
        endDate: moment
          .utc(Date.now())
          .endOf("month")
          .toISOString()
          .substr(0, 10)
      },
      menuStart: false,
      menuEnd: false,
      order_type_list: configs.ORDER_TYPE_LIST
    };
  },
  watch: {
    pagination: {
        handler: function (val, oldVal) {
            let filterConditions = this.$store.state.commons.filterConditions;
            this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
            this.filter_data();
        },
        deep: true
    },
  },
  computed: {
    ...mapState({
      items: state => state.report.commissionReport.data,
      total_rows: state => state.report.commissionReport.total,
      summary: state => state.report.commissionReport.summary.summary,
      summary_staff: state => state.report.commissionReport.summary.summary_staff,
      loading: state => state.report.loading
    }),
    computedStartDateFormatted() {
      return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
    },
    computedEndDateFormatted() {
      return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
    },
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    }
  },
  methods: {
    filter_data: _.debounce(function () {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions,
        startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
        endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT),
      };
      this.$store.dispatch("report/getCommissionReport", param);
    }, 300, {
        'leading': true,
        'trailing': false
    }),
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy
      let sortDesc = this.pagination.sortDesc
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    }
  },
  mounted() {
    this.$root.$emit("REQUEST_ROOT_OPTION", {});
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
  }
};
</script>